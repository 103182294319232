<template>
    <section>
        <InsideHeader :title="name" :bredcrumb="breadcrumb" />   
        <component
            :is="dynamicComponent"
        ></component>  
    </section>
</template>

<script>
import InsideHeader from "../components/InsideHeader.vue";
import Translation from '../../public/translation.json'

export default {
    components: {InsideHeader},
    data(){
        return {
            breadcrumb: [],
            slug: '',
            name: '',
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    computed: {
        dynamicComponent(){            
            let fileName = this.$options.filters.capitalize(this.slug);
            return () => import(`../components/Auth/${fileName}`);
        }
    },
    methods: {
        checkAuth(){
            this.$store.dispatch('validateUser').then(res=>{
                if(res.data.status === true) {
                    if(res.data.name !== '')
                        this.$router.push('/user/dashboard')
                }
            })
            .catch(error=>{
                //console.log(error)
            })
        },
        setBreadCrumb(){
            this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.name, path:'#'}                
            ]
        },
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        } 
    },
    created: function(){
        this.checkAuth()
        this.slug = this.$route.meta.slug;
        this.name = this.pageContent(this.$route.name.replace(' ', '_').toLowerCase());
        this.setBreadCrumb()
    },
    watch: {
        '$route.meta.slug'(){
            this.slug = this.$route.meta.slug
        },
        '$route.name'(){
            this.name = this.pageContent(this.$route.name.replace(' ', '_').toLowerCase());
            this.setBreadCrumb()        
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString();
            let strArry = value.split('-')
            if(strArry.length > 1){
                return strArry[0].charAt(0).toUpperCase() + strArry[0].slice(1) + strArry[1].charAt(0).toUpperCase() + strArry[1].slice(1)
            }
            else 
                return value.charAt(0).toUpperCase() + value.slice(1)
        }
    } 
    
}
</script>